import { Auth0Provider } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';

import AmplitudeProvider from 'lib/contexts/Amplitude';
import App from 'pages/App';

import { getAuthorizationParams } from './lib/helpers/getAuthorizationParams';
import ThemeProvider from './ThemeProvider';

import 'antd/dist/reset.css';
import './auth0.css';

console.log(
  `%cUsercentrics - Account Management - v${process.env.REACT_APP_VERSION}`,
  'color:#1d60b3;font-size:14px;font-weight:bold',
);

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER || '';
const params: URLSearchParams = new URL(window.location.href).searchParams;

const glValue = params.get('_gl') || Cookies.get('_gl');

if (glValue) {
  Cookies.set('_gl', glValue);
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('_gl', glValue);
  window.history.replaceState({}, '', currentUrl.toString());
}

TagManager.initialize({ gtmId });

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    authorizationParams={await getAuthorizationParams()}
  >
    <ThemeProvider>
      <AmplitudeProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <App />
        </Router>
      </AmplitudeProvider>
    </ThemeProvider>
  </Auth0Provider>,
);
